export const apiGetList = "SystemCate/GetList"
export const apiChangeStatus = "SystemCate/ChangeStatus"
export const apiChangeStatusItem = "SystemCate/ChangeStatusItem"
export const apiChangePermission = "SystemCate/ChangePermission"
export const apiGetListTaiLieu = "SystemCate/TAI_LIEU"
export const apiGetListPhi = "SystemCate/PHI"
export const apiGetListNgonNgu = "SystemCate/NGON_NGU"
export const apiGetListFileDinhKem = "SystemCate/FILE_DINH_KEM"
export const apiGetListLoaiNguoiNopDon = "SystemCate/LOAI_NGUOI_NOP_DON"
export const apiGetListQuocGia = "SystemCate/QUOC_GIA"
export const apiGetListCauHinhQRThanhToan = "SystemCate/CAU_HINH_QR_THANH_TOAN"
export const apiGetListKichThuocHinhAnh = "SystemCate/KICH_THUOC_HINH_ANH"
export const apiGetListCauHinhKySo = "SystemCate/CAU_HINH_KI_SO"
export const apiInsertItem = "SystemCate/InsertItem"
export const apiUpdateItem = "SystemCate/UpdateItem"
export const apiDeleteItem = "SystemCate/DeleteItem"
export const apiGetForCombobox = "SystemCate/GetForCombobox"
export const apiImportSystemCate = "SystemCate/ImportSystemCate"
export const apiFileTemplateImportSystemCate =
  "SystemCate/FileTemplateImportSystemCate"
