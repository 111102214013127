import {
  apiGetList,
  apiChangeStatus,
  apiChangeStatusItem,
  apiChangePermission,
  apiGetListTaiLieu,
  apiGetListPhi,
  apiGetListNgonNgu,
  apiGetListFileDinhKem,
  apiGetListLoaiNguoiNopDon,
  apiGetListQuocGia,
  apiGetListCauHinhQRThanhToan,
  apiGetListKichThuocHinhAnh,
  apiGetListCauHinhKySo,
  apiInsertItem,
  apiUpdateItem,
  apiDeleteItem,
  apiGetForCombobox,
  apiImportSystemCate,
  apiFileTemplateImportSystemCate,
} from "./urls"
import http from "../index"

const getList = data => http.post(apiGetList, data)
const changeStatus = data => http.post(apiChangeStatus, data)
const changeStatusItem = data => http.post(apiChangeStatusItem, data)
const changePermission = data => http.post(apiChangePermission, data)
const getListTaiLieu = body => http.post(apiGetListTaiLieu, body)
const getListPhi = body => http.post(apiGetListPhi, body)
const getListNgonNgu = body => http.post(apiGetListNgonNgu, body)
const getListFileDinhKem = body => http.post(apiGetListFileDinhKem, body)
const getListLoaiNguoiNopDon = body =>
  http.post(apiGetListLoaiNguoiNopDon, body)
const getListQuocGia = body => http.post(apiGetListQuocGia, body)
const getListCauHinhQRThanhToan = body =>
  http.post(apiGetListCauHinhQRThanhToan, body)
const getListThuocHinhAnh = body => http.post(apiGetListKichThuocHinhAnh, body)
const getListCauHinhKySo = body => http.post(apiGetListCauHinhKySo, body)
const insertItem = data => http.post(apiInsertItem, data)
const updateItem = data => http.post(apiUpdateItem, data)
const deleteItem = ItemID => http.patch(`${apiDeleteItem}?input=${ItemID}`)
const getForCombobox = () => http.get(apiGetForCombobox)
const importSystemCate = body =>
  http.post(`${apiImportSystemCate}?CodeKey=${body?.CodeKey}`, body?.formData)
const fileTemplateImportSystemCate = id =>
  http.get(`${apiFileTemplateImportSystemCate}?CodeKey=${id}`, {
    responseType: "blob",
  })

const SystemCateService = {
  getList,
  changeStatus,
  changeStatusItem,
  changePermission,
  getListTaiLieu,
  getListPhi,
  getListNgonNgu,
  getListFileDinhKem,
  getListLoaiNguoiNopDon,
  getListQuocGia,
  getListCauHinhQRThanhToan,
  getListThuocHinhAnh,
  getListCauHinhKySo,
  insertItem,
  updateItem,
  deleteItem,
  getForCombobox,
  importSystemCate,
  fileTemplateImportSystemCate,
}
export default SystemCateService
