const CA_NHAN = "ca_nhan"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/trang-chu",
  DEFAULT: "/",
  DANG_NHAP: "/dang-nhap",
  DOI_MAT_KHAU: "/doi-mat-khau",
  TEST_PRINT: "/test-print",

  //Đại biểu

  DANG_SACH_CHUONG_TRINH_HOP: "/danh-sach-chuong-trinh-hop",
  VI_TRI_NGOI_HOP_DAI_BIEU: "/vi-tri-ngoi-hop-dai-bieu",

  DANG_KY_PHAT_BIEU: `/dang-ky-phat-bieu`,
  DUYET_KET_LUAN_HOP: "/duyet-ket-luan-hop",
  THONG_BAO_CUA_DAI_BIEU: `/thong-bao-cua-dai-bieu`,
  GHI_CHU: `/ghi-chu`,
  XIN_Y_KIEN: `/xin-y-kien`,
  BIEU_QUYET_DAI_BIEU: "/bieu-quyet-dai-bieu",

  // ADMIN
  THONG_TIN_HAU_CAN: "/thong-tin-hau-can",
  Y_KIEN_CU_TRI: "/y-kien-cu-tri",
  TAI_LIEU: "/tai-lieu",
  TONG_HOP_BAO_CHI: "/tong-hop-bao-chi",
  VAN_KIEN_TAI_LIEU: "/van-kien-tai-lieu",

  LS_HOAT_DONG: "/ls-hoat-dong",
  DANH_SACH_CHUC_VU: "/danh-sach-chuc-vu",
  NHAT_KY_HE_THONG: "/nhat-ky-he-thong",
  LICH_HOP: "/lich-hop",

  DAT_LICH: "/dat-lich",
  VI_TRI_NGOI_HOP: "/vi-tri-ngoi-hop",
  THONG_BAO_MOI_HOP: "/thong-bao-moi-hop",
  NOTIFY: "/notify",
  THONG_BAO: "/thong-bao",
  NHOM_CAN_BO: "/nhom-can-bo",
  NHOM_TAI_LIEU: "/nhom-tai-lieu",
  KET_LUAN_HOP_MAU: "/ket-luan-hop-mau",
  BIEU_QUYET: "/bieu-quyet",
  KET_QUA_BIEU_QUYET: "/ket-qua-bieu-quyet",
  DIEU_HANH_CUOC_HOP: "/dieu-hanh-cuoc-hop",

  DANH_SACH_DANG_KY_PHAT_BIEU: `/danh-sach-dang-ky-phat-bieu`,
  KET_LUAN_HOP: "/ket-luan-hop",

  //Danh mục
  DANH_MUC: "/danh-muc",
  // --------------------
  DIA_DIEM: "/dia-diem",
  HAU_CAN_DICH_VU_KHAC: "/hau-can-dich-vu-khac",
  UY_BAN: "/uy-ban",
  QUAN_LY_PHAN_QUYEN: "/quan-ly-phan-quyen",
  NHOM_NGUOI_SU_DUNG: "/nhom-nguoi-su-dung",
  GOP_Y_TAI_LIEU: "/gop-y-tai-lieu",
  TO: "/to",
  // --------------------------
  DANH_SACH_XIN_Y_KIEN: "/danh-sach-xin-y-kien",

  DOAN_CAN_BO: "/doan-can-bo",
  CAN_BO: "/can-bo",
  //Quản lý phòng họp
  QUAN_LY_PHONG_HOP: "/quan-ly-phong-hop",
  // --------------------
  PHONG_HOP: "/phong-hop",
  SO_DO_PHONG_HOP: "/so-do-phong-hop",
  LOAI_PHONG_HOP: "/loai-phong-hop",
  CHUONG_TRINH_HOP: "/chuong-trinh-hop",
  // --------------------
  DANG_KY_LICH: "/dang-ky-lich",
  //User
  CA_NHAN: CA_NHAN,
  THONG_TIN_TAI_KHOAN: `/thong-tin-ca-nhan`,
  CHI_TIET_THONG_BAO: `/chi-tiet-thong-bao`,
  GIAY_MOI_HOP: `/giay-moi-hop`,

  // THONG_TIN_TAI_KHOAN: `/${CA_NHAN}/thong-tin-ca-nhan`,

  TONG_QUAN: `/${CA_NHAN}/tong-quan`,
  LS_HOAT_DONG_USER: `/${CA_NHAN}/ls-hoat-dong-user`,
  PHIEU_XIN_Y_KIEN: "/phieu-xin-y-kien",
  // Router
  // PRINT: "/print",
}
export default ROUTER
