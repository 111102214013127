import http from "../index"
import {
  apiGetListNotify,
  apiGetNewNotification,
  apiMarkAsRead,
  apiMarkAsSeen,
  apiDeleteNotifyForUser,
  apiGetListByReferenceId,
  apiGetListNotifyTable,
  apiInsertNotifyTable,
  apiUpdateNotifyTable,
  apiDeleteNotifyTable,
} from "./urls"
import QueryString from "qs"

//Chuông
const GetListNotify = body => http.post(apiGetListNotify, body)
const GetNewNotification = () => http.get(apiGetNewNotification)
const MarkAsRead = params => http.patch(apiMarkAsRead + params)
const MarkAsSeen = params => http.patch(apiMarkAsSeen + params)
const DeleteNotifyForUser = params =>
  http.patch(apiDeleteNotifyForUser + params)
const getListByReferenceId = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiGetListByReferenceId}?${params}`)
}

//Thông báo
const getListNotifyTable = body => http.post(apiGetListNotifyTable, body)
const insertNotifyTable = body => http.post(apiInsertNotifyTable, body)
const updateNotifyTable = body => http.post(apiUpdateNotifyTable, body)

const deleteNotifyTable = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiDeleteNotifyTable}?${params}`)
}

const NotifyService = {
  GetListNotify,
  getListNotifyTable,
  insertNotifyTable,
  updateNotifyTable,
  deleteNotifyTable,
  GetNewNotification,
  MarkAsRead,
  MarkAsSeen,
  DeleteNotifyForUser,
  getListByReferenceId,
}
export default NotifyService
