//Notify
export const apiGetListNotify = "Notify/GetList"
export const apiGetNewNotification = "Notify/GetNewNotification"
export const apiMarkAsRead = "Notify/MarkAsRead?NotifyId="
export const apiMarkAsSeen = "Notify/MarkAsSeen?NotifyId="
export const apiDeleteNotifyForUser = "Notify/DeleteNotifyForUser?NotifyId="
export const apiGetListByReferenceId = "Notify/GetListByReferenceId"

//Thông báo
export const apiGetListNotifyTable = "Notify/GetListNotifyTable"
export const apiInsertNotifyTable = "Notify/InsertNotifyTable"
export const apiUpdateNotifyTable = "Notify/UpdateNotifyTable"
export const apiDeleteNotifyTable = "Notify/DeleteNotifyTable"
