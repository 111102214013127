import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import SpinCustom from "src/components/Spin"

// ANONYMOUS
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))

const MeetingInvitation = React.lazy(() =>
  import("src/pages/SvgViewer/MeetingInvitation"),
)
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/USER/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() =>
  import("src/components/Layouts/component/ChangePassword"),
)
const OpinionForm = React.lazy(() =>
  import("src/pages/USER/RequestOpinion/component/RequestOpinionForm"),
)

const Voting = React.lazy(() => import("src/pages/USER/Voting"))
const ManagementVotingResult = React.lazy(() =>
  import("src/pages/USER/ManagementVotingResult"),
)

const RegisterSpeak = React.lazy(() => import("src/pages/USER/RegisterSpeak"))
const ApproveMeetingConclusions = React.lazy(() =>
  import("src/pages/USER/ApproveMeetingConclusions"),
)

const Note = React.lazy(() => import("src/pages/USER/Note"))
const NotifyUser = React.lazy(() => import("src/pages/USER/NotifyUser"))
const RequestOpinion = React.lazy(() => import("src/pages/USER/RequestOpinion"))
// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))

const Overview = React.lazy(() => import("src/pages/USER/Overview"))
const PersonProfile = React.lazy(() => import("src/pages/USER/PersonProfile"))
const MeetingConfirmation = React.lazy(() =>
  import("src/pages/USER/MeetingConfirmation"),
)

const SpeakingRegistrationList = React.lazy(() =>
  import("src/pages/USER/SpeakingRegistrationList"),
)

const ListMeetingSchedule = React.lazy(() =>
  import("src/pages/USER/ListMeetingSchedule"),
)
const PositionUser = React.lazy(() => import("src/pages/USER/PositionUser"))

const MeetingSchedule = React.lazy(() =>
  import("src/pages/USER/MeetingSchedule"),
)
const ListDosier = React.lazy(() => import("src/pages/USER/ListDosier"))

const LogisticsInformation = React.lazy(() =>
  import("src/pages/ADMIN/LogisticsInformation"),
)
const DocumentManager = React.lazy(() =>
  import("src/pages/ADMIN/DocumentManager"),
)

const PressSummary = React.lazy(() => import("src/pages/ADMIN/PressSummary"))

const ScheduleManagement = React.lazy(() =>
  import("src/pages/ADMIN/ScheduleManagement"),
)
const ManageSittingPosition = React.lazy(() =>
  import("src/pages/ADMIN/ManageSittingPosition"),
)
// const CategoryManager = React.lazy(() =>
//   import("src/pages/ADMIN/CategoryManager"),
// )
//Danh mục
const ManagerLocation = React.lazy(() =>
  import("src/pages/ADMIN/ManagerLocation"),
)
const LogisticsAndOtherServices = React.lazy(() =>
  import("src/pages/ADMIN/LogisticsAndOtherServices"),
)
const Committee = React.lazy(() => import("src/pages/ADMIN/Committee"))

const ManagerMeetingInvitationNotice = React.lazy(() =>
  import("src/pages/ADMIN/ManagerMeetingInvitationNotice"),
)
const NotifyManager = React.lazy(() => import("src/pages/ADMIN/NotifyManager"))
const ManagerNotification = React.lazy(() =>
  import("src/pages/ADMIN/ManagerNotification"),
)

const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
const UserGroup = React.lazy(() => import("src/pages/ADMIN/UserGroup"))
const DocumentComments = React.lazy(() =>
  import("src/pages/ADMIN/DocumentComments"),
)
const Groups = React.lazy(() => import("src/pages/ADMIN/Groups"))

const HistoryActive = React.lazy(() => import("src/pages/ADMIN/HistoryActive"))
const ManageDocumentGroups = React.lazy(() =>
  import("src/pages/ADMIN/ManageDocumentGroups"),
)
const SampleMeetingConclusion = React.lazy(() =>
  import("src/pages/ADMIN/SampleMeetingConclusion"),
)

const ManagementVoting = React.lazy(() =>
  import("src/pages/ADMIN/ManagementVoting"),
)
const PleaseAdvise = React.lazy(() => import("src/pages/ADMIN/PleaseAdvise"))
const StaffGroup = React.lazy(() => import("src/pages/ADMIN/StaffGroup"))
const Officer = React.lazy(() => import("src/pages/ADMIN/StaffGroup"))

const PositionManager = React.lazy(() =>
  import("src/pages/ADMIN/PositionManager"),
)

const ManagingMeetingOperations = React.lazy(() =>
  import("src/pages/ADMIN/ManagingMeetingOperations"),
)
const MeetingRoom = React.lazy(() => import("src/pages/ADMIN/MeetingRoom"))
const DiagramMeetingRoomType = React.lazy(() =>
  import("src/pages/ADMIN/DiagramMeetingRoomType"),
)

const MeetingProgram = React.lazy(() =>
  import("src/pages/ADMIN/MeetingProgram"),
)
const RegisterCalendar = React.lazy(() =>
  import("src/pages/ADMIN/RegisterCalendar"),
)

const MeetingConclusion = React.lazy(() =>
  import("src/pages/ADMIN/MeetingConclusion"),
)
const ManageGroupOfStaff = React.lazy(() =>
  import("src/pages/ADMIN/ManageGroupOfStaff"),
)

const SystemHistory = React.lazy(() => import("src/pages/ADMIN/SystemHistory"))
function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <SpinCustom />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },

  {
    path: ROUTER.DANH_SACH_DANG_KY_PHAT_BIEU,
    element: (
      <LazyLoadingComponent>
        <SpeakingRegistrationList />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.GIAY_MOI_HOP,
    element: (
      <LazyLoadingComponent>
        <MeetingInvitation />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.THONG_TIN_HAU_CAN,
        element: (
          <LazyLoadingComponent>
            <LogisticsInformation />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.Y_KIEN_CU_TRI,
        element: (
          <LazyLoadingComponent>
            <LogisticsInformation />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <DocumentManager />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TONG_HOP_BAO_CHI,
        element: (
          <LazyLoadingComponent>
            <PressSummary />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DAT_LICH,
        element: (
          <LazyLoadingComponent>
            <ScheduleManagement />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.VI_TRI_NGOI_HOP,
        element: (
          <LazyLoadingComponent>
            <ManageSittingPosition />
          </LazyLoadingComponent>
        ),
      },
      ////Danh mục
      // {
      //   path: ROUTER.DANH_MUC,
      //   element: (
      //     <LazyLoadingComponent>
      //       <CategoryManager />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.DIA_DIEM,
        element: (
          <LazyLoadingComponent>
            <ManagerLocation />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HAU_CAN_DICH_VU_KHAC,
        element: (
          <LazyLoadingComponent>
            <LogisticsAndOtherServices />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.UY_BAN,
        element: (
          <LazyLoadingComponent>
            <Committee />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.QUAN_LY_PHAN_QUYEN,
      //   element: (
      //     <LazyLoadingComponent>
      //       <UserGroup />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.QUAN_LY_PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.GOP_Y_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <DocumentComments />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO,
        element: (
          <LazyLoadingComponent>
            <Groups />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_BAO_MOI_HOP,
        element: (
          <LazyLoadingComponent>
            <ManagerMeetingInvitationNotice />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NOTIFY,
        element: (
          <LazyLoadingComponent>
            <NotifyManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_BAO,
        element: (
          <LazyLoadingComponent>
            <ManagerNotification />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.NHOM_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <ManageDocumentGroups />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.KET_LUAN_HOP_MAU,
        element: (
          <LazyLoadingComponent>
            <SampleMeetingConclusion />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.BIEU_QUYET,
        element: (
          <LazyLoadingComponent>
            <ManagementVoting />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_XIN_Y_KIEN,
        element: (
          <LazyLoadingComponent>
            <PleaseAdvise />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DOAN_CAN_BO,
        element: (
          <LazyLoadingComponent>
            <StaffGroup />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAN_BO,
        element: (
          <LazyLoadingComponent>
            <Officer />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DANH_SACH_CHUC_VU,
        element: (
          <LazyLoadingComponent>
            <PositionManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DIEU_HANH_CUOC_HOP,
        element: (
          <LazyLoadingComponent>
            <ManagingMeetingOperations />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHONG_HOP,
        element: (
          <LazyLoadingComponent>
            <MeetingRoom />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SO_DO_PHONG_HOP,
        element: (
          <LazyLoadingComponent>
            <DiagramMeetingRoomType />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHUONG_TRINH_HOP,
        element: (
          <LazyLoadingComponent>
            <MeetingProgram />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY_LICH,
        element: (
          <LazyLoadingComponent>
            <RegisterCalendar />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.KET_LUAN_HOP,
        element: (
          <LazyLoadingComponent>
            <MeetingConclusion />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.NHOM_CAN_BO,
        element: (
          <LazyLoadingComponent>
            <ManageGroupOfStaff />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LS_HOAT_DONG,
        element: (
          <LazyLoadingComponent>
            <HistoryActive />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAT_KY_HE_THONG,
        element: (
          <LazyLoadingComponent>
            <SystemHistory />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TONG_QUAN,
        element: (
          <LazyLoadingComponent>
            <Overview />
          </LazyLoadingComponent>
        ),
      },
      // ------------------------------------------

      {
        path: ROUTER.KET_QUA_BIEU_QUYET,
        element: (
          <LazyLoadingComponent>
            <ManagementVotingResult />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.KET_QUA_BIEU_QUYET}/:id`,
        element: (
          <LazyLoadingComponent>
            <ManagementVotingResult />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY_PHAT_BIEU,
        element: (
          <LazyLoadingComponent>
            <RegisterSpeak />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DUYET_KET_LUAN_HOP,
        element: (
          <LazyLoadingComponent>
            <ApproveMeetingConclusions />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.GHI_CHU,
        element: (
          <LazyLoadingComponent>
            <Note />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_BAO_CUA_DAI_BIEU,
        element: (
          <LazyLoadingComponent>
            <NotifyUser />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.XIN_Y_KIEN,
        element: (
          <LazyLoadingComponent>
            <RequestOpinion />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHIEU_XIN_Y_KIEN,
        element: (
          <LazyLoadingComponent>
            <OpinionForm />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.LICH_HOP,
        element: (
          <LazyLoadingComponent>
            <MeetingSchedule />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.VAN_KIEN_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <ListDosier />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DANG_SACH_CHUONG_TRINH_HOP,
        element: (
          <LazyLoadingComponent>
            <ListMeetingSchedule />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.VI_TRI_NGOI_HOP_DAI_BIEU,
        element: (
          <LazyLoadingComponent>
            <PositionUser />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.THONG_TIN_TAI_KHOAN,
        element: (
          <LazyLoadingComponent>
            <PersonProfile />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHI_TIET_THONG_BAO,
        element: (
          <LazyLoadingComponent>
            <MeetingConfirmation />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.BIEU_QUYET_DAI_BIEU,
        element: (
          <LazyLoadingComponent>
            <Voting />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DEFAULT,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
